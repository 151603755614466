<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/dbhg/dengbao3_800.jpg">
                </div>
				<div class="con_top_right">
					<h2>等保三级安全合规产品（云上系统）</h2>
					<p class="new_tit">为客户提供整体合规解决方案和提升安全防护能力，包括云防火墙、云安全中心、云日志审计、云堡垒机、数据库审计、Web应用防火墙等安全能力，可以帮助客户有效地完成云安全整改，以满足等保的基础合规技术要求。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                           <ul>
                               <li>云等保总体分为两个部分，技术要求、管理要求。</li>
                               <li>云服务商主要协助完成“技术要求”。物理和环境安全可以复用云平台自身的安全检测结果，而其他技术要求则需要叠加云上的安全服务产品进行满足。</li>
                              
                           </ul>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
            <div class="system">
                <h2>等保三级安全合规产品（云上系统）</h2>
                <div class="system_content">
                    <p>等保2.         等保2.0扩展了云计算安全要求，云等保合规也成为了企业上云必须完成的基本需求。根据《网络安全法》和国家相关政策，为客户提供整体合规解决方案和提升安全防护能力，包括云防火墙、云安全中心、云日志审计、云堡垒机、数据库审计、Web应用防火墙等安全能力，可以帮助客户有效地完成云安全整改，以满足等保的基础合规技术要求。</p>
                    <ul>
                        <li>
                            <img src="../../assets/images/dbhg/01.png" alt="">
                        </li>
                        <li>
                            <img src="../../assets/images/dbhg/02.png" alt="">
                        </li>
                        <li>
                            <img src="../../assets/images/dbhg/03.png" alt="">
                        </li>
                        <li>
                            <img src="../../assets/images/dbhg/04.png" alt="">
                        </li>
                    </ul>
                </div>
            </div>

            <div class="protect">
                <div class="title">
                    <h3>等级保护（2.0云等保）</h3>
                </div>
                <div class="protect_content">
                    <ul>
                        <li>云等保总体分为</li>
                        <li>
                            <img src="../../assets/images/dbhg/013.png" alt="">
                        </li>
                        <li>技术要求</li>
                        <li>+</li>
                        <li>管理要求</li>
                    </ul>
                    <p>       云服务商主要协助完成“技术要求”。物理和环境安全可以复用云平台自身的安全检测结果，而其他技术要求则需要叠加云上的安全服务产品进行满足。</p>
                </div>
            </div>


            <div class="business">
                 <div class="title">
                    <h3>业务开展的必要性</h3>
                </div>
                <div class="business_content">
                    <ul>
                        <li>
                            <div class="ness">
                            <h5>法律法规要求：</h5>
                            <img src="../../assets/images/dbhg/05.png" alt="">
                            <p>《网络安全法》明确规定信息系统运营、使用单位应当按照网络安全等级保护制度要求，履行安全保护义务，如拒不履行，将会受到相应处罚。</p>
                            </div>
                        </li>
                        <li>
                            <div class="ness">
                            <h5>行业准入要求：</h5>
                            <img src="../../assets/images/dbhg/06.png" alt="">
                            <p>在金融、电力、广电、医疗、教育行业，主管单位明确要求企业开展等级保护工作。</p>
                            </div>
                        </li>
                        <li>
                            <div class="ness">
                            <h5>法律法规要求：</h5>
                            <img src="../../assets/images/dbhg/07.png" alt="">
                            <p>开展等级保护工作可以发现企业本身信息系统存在的安全隐患和不足，通过安全整改提升信息系统的信息安全防护能力。</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

              <div class="profit">
                 <div class="title">
                    <h3>客户收益</h3>
                </div>
                <div class="profit_content">
                    <ul>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/dbhg/08.png" alt="">
                            </div>
                            <p>满足合规性需求</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/dbhg/09.png" alt="">
                            </div>
                            <p>保护企业核心信息及客户信息</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/dbhg/010.png" alt="">
                            </div>
                            <p>建立完善的网络安全管理制度，长治久安</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/dbhg/011.png" alt="">
                            </div>
                            <p>了解业务安全边界，提升<br>业务系统安全和网络防护能力</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/dbhg/012.png" alt="">
                            </div>
                            <p>有效提升公信度，增强<br>公共网络和信息系统的可信<br>度，相比未过等保的，更有竞争力</p>
                        </li>
                    </ul>
                </div>
              </div>

                <div class="detailed">
                    <div class="title">
                        <h3>产品详细</h3>
                    </div>
                    <div class="detailed_content">
                        <div class="equipment">
                           
                                <div> <p> 1. 在公有云上过等<br>保需要哪些安全设 <br>备?</p></div>
                                <div>云防火墙</div>
                                <div>云堡垒机</div>
                                <div>云安全中心</div>
                                <div>云日志审计</div>
                                <div>数据库审计</div>
                                <div><p>Web应用防火墙</p></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            
                        </div>

                        <div class="table">
                            <h4>2.万方公有云二级等保整改套餐</h4>
                            <p>       万方安全为广大公有云（阿里云、腾讯云、华为云）等云平台客户提供等保测评要求的安全产品，同时配套提供安全产品部署及配置服务，让客户更省钱、省心地通过等保测评。</p>
                            <table>
                                <tbody>
                                <tr>
                                    <th>产品名称</th>
                                    <th>合规对应要求</th>
                                </tr>
                                <tr>
                                    <th>云防火墙</th>
                                    <th>满足等保要求中的边界防护和访问控制等要求</th>
                                </tr>
                                <tr>
                                    <th>云安全中心</th>
                                    <th>满足等保要求中安全审计和集中管控的要求</th>
                                </tr>
                                <tr>
                                    <th>云日志审计</th>
                                    <th>满足等保要求中日志审计和存储时间的要求</th>
                                </tr>
                                <tr>
                                    <th>云堡垒机</th>
                                    <th>满足等保要求中身份鉴别、访问控制和安全审计等要求</th>
                                </tr>
                                <tr>
                                    <th>数据库审计</th>
                                    <th>满足等保要求中数据库安全审计等要求</th>
                                </tr>
                                <tr>
                                    <th>Web应用防火墙</th>
                                    <th>满足等保要求中的入侵防范、Web安全防护等要求</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="advantage">
                    <div class="title">
                        <h3>我们的优势</h3>
                    </div>
                    <div class="advantage_content">
                        <div class="age">

                        <ul>
                            <li>
                                <h4>技术优势：</h4>
                                <p>安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</p>
                            </li>
                            <li>
                                <h4>价格优势：</h4>
                                <p>万方安全产品套餐价格比主流云平台产品价格更优惠。</p>
                            </li>
                            <li>
                                <h4>服务优势：</h4>
                                <p>万方安全技术专家帮助客户部署及配置。</p>
                            </li>
                        </ul>
                        </div>
                        <div class="vant">
                            <div class="vant_left">
                                <ul>
                                    <li>1.国家信息安全服务资质(安全工程类)</li>
                                    <li>2.国家信息安全风险评估资质</li>
                                    <li>3.国家通信网络安全服务风险评估资质</li>
                                    <li>4.国家信息安全应急处理服务资质</li>
                                    <li>5.国家信息系统安全集成服务资质</li>
                                    <li>6.国家信息安全漏洞库支持单位</li>
                                    <li>7.国际信息安全管理体系ISO 27001认证</li>
                                    <li>8.国际质量管理体系ISO9001认证</li>
                                    <li>9.国际环境管理体系ISO 14001认证</li>
                                    <li>10.高新技术企业</li>
                                    <li>11.软件企业</li>
                                    <li>12.企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="vant_right">
                                <img src="../../assets/images/dbhg/zz.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="case">
                    <div class="title">
                    <h3>典型案例</h3>
                    </div>
                    <div class="case_content">
                        <ul>
                            <li>
                                <p>1.广州某某智能物流公司</p>
                                <p>需求：该公司货运平台系统等级保护测评项目(三级)</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，省去大部分过度设备投资，用最小最优的成本实现等保过保。</p>
                            </li>
                            <li>
                                <p>2.深圳市某某中学 </p>
                                <p>需求：等保二级</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，顺利通过等级保护测评机构的测评验收工作，取得公安部颁发的相应等保证书。</p>
                            </li>
                            <li>
                                <p>3.杭州某某某网络科技有限公司</p>
                                <p>需求：某某某出行系统等级保护测评项目（三级）</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，在安全整改时，根据实际需求和合理安排，省去大部分过度设备投资，用最小最优的成本取得三级等级保护证书。</p>
                            </li>
                        </ul>
                    </div>
                </div>


			


				
 
			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/ie/dbcping_358.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东地区等保测评...</p>
                                        <p>协助客户按照有关...</p>
                                        <p>在线咨询</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/assessment">
                                            <img src="@/assets/images/app/008.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>安全评估报告（全国互联网安全管理服务平台要求提交的报告）</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.system{
    h2{
        margin-top: 100px;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        color: #0067AC;
    }
    .system_content{
        margin-top: 30px;
        p{
            font-size: 22px;
            line-height: 26px;
            text-indent: 2em;
            padding: 0px 20px;
        }
        ul{
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 30px;
            li{
                width: 164px;
                height: 132px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.protect{
    margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .protect_content{
        margin-top:30px;
        ul{
            display: flex;
            align-items: center;
            justify-content: space-around;
            li{
                font-size: 25px;
            }
            :nth-child(1){
                background-color: #0067AC;
                color: white;
                width: 230px;
                line-height: 70px;
                text-align: center;
            }
            :nth-child(2){
               width: 44px;
               height: 44px;
               img{
                   width: 100%;
                   height: 100%;
               }
            }
            :nth-child(3){
                 width: 230px;
                line-height: 70px;
                border: 1px solid #0067AC;
                color: #0067AC;
                text-align: center;
            }
            :nth-child(4){
                font-size: 50px;
                color: #0067AC;
                font-weight: bold;
            }
            :nth-child(5){
                 width: 230px;
                line-height: 70px;
                border: 1px solid #0067AC;
                color: #0067AC;
                text-align: center;
            }
        }
        p{
            margin-top: 30px;
            font-size: 22px;
            line-height: 26px;
            text-indent: 2em;
            padding: 0px 30px;
            color: #0067AC;
        }
    }
}

.business{
        margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .business_content{
        margin-top: 30px;
        ul{
            display: flex;
            align-items: center;
            justify-content: space-around;
            li{
                width: 269px;
                height: 382px;
                border: 2px solid #0067AC;
                border-radius: 7px;  
                .ness{
                    padding: 0 20px;
                
                h5{
                    margin-top: 26px;
                    font-size: 22px;
                    color: #0067AC;
                }
                img{
                    float: right;
                }
                p{
                    margin-top: 120px;
                    font-size: 22px;
                    line-height: 30px;
                  
                }
                }
            }
        }
    }
}

.profit{
     margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .profit_content{
        margin-top: 70px;
        ul{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                 margin-bottom: 50px;
                .lis_img{
                    width: 122px;
                    height: 122px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    font-size: 22px;
                    line-height: 24px;
                    color: #0067AC;
                    width: 154px;
                    text-align: center;
                    margin-top: 30px;
                }
            }
            :nth-child(3){
               
                p{
                    width: 220px;
                }
            }
            :nth-child(4){
                p{
                    width: 353px;
                }
            }
            :nth-child(5){
                p{
                    width: 330px;
                }
            }
        }
    }
}

.detailed{
    margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .detailed_content{
        .equipment{
                position: relative;
                width: 100%;
                height: 700px;
                div:nth-child(1){
                    position: absolute;
                    width: 295px;
                    height: 164px;
                    border-radius: 50%;
                    border: 2px solid #0067AC;
                    color: #0067AC;
                    background-color: white;
                    top: 242px;
                    left: 0;
                    right: 0;
                    margin:auto;
                    text-align: center;
                    p{
                        font-size: 25px;
                        margin-top:50px;
                        line-height: 27px;
                    }
                }
                div:nth-child(2){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 264px;
                    left: 22px; 
                    margin:auto;
                    text-align: center;
                    font-size: 25px;
                    line-height: 140px;
                }
                div:nth-child(3){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 226px;
                    left: 695px; 
                    margin:auto;
                    text-align: center;
                    font-size: 25px;
                    line-height: 140px;
                }
                div:nth-child(4){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 459px;
                    left: 208px; 
                    margin:auto;
                    text-align: center;
                    font-size: 25px;
                    line-height: 140px;
                }
                div:nth-child(5){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 451px;
                    left: 513px; 
                    margin:auto;
                    text-align: center;
                    font-size: 25px;
                    line-height: 140px;
                }
                div:nth-child(6){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 49px;
                    left: 188px; 
                    margin:auto;
                    text-align: center;
                    font-size: 25px;
                    line-height: 140px;
                }
                div:nth-child(7){
                    position: absolute;
                    width: 149px;
                    height: 140px;
                    border-radius: 50%;
                    color: white;
                    background-color: #0067AC;
                    top: 26px;
                    left: 525px; 
                    margin:auto;
                    text-align: center;
                    p{
                        font-size: 25px;
                        margin-top:50px;
                        line-height: 27px;
                    }
                }


                div:nth-child(8){
                    position: absolute;
                    width: 100px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(-126.12deg);
                    top: 215px;
                    left: 282px;
                    margin: auto;
                }
                div:nth-child(9){
                    position: absolute;
                    width: 120px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(-50.7deg);
                    top: 200px;
                    left: 469px;
                    margin: auto;
                }
                div:nth-child(10){
                    position: absolute;
                    width: 122px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(-7.03deg);
                    top: 320px;
                    left: 170px;
                    margin: auto;
                }
                div:nth-child(11){
                    position: absolute;
                    width: 126px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(9.3deg);
                    top: 300px;
                    left: 573px;
                    margin: auto;
                }
                div:nth-child(12){
                    position: absolute;
                    width: 79px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(-118.27deg);
                    top: 423px;
                    left: 514px;
                    margin: auto;
                }
                div:nth-child(13){
                    position: absolute;
                    width: 90px;
                    height: 1px;
                    background-color: #0067AC;
                    transform:rotate(-57.3deg);
                    top: 427px;
                    left: 278px;
                    margin: auto;
                }
            
        }
        .table{
            margin-top: 50px;
            padding: 0 20px;
            h4{
                font-size: 25px;
                color: #0067AC;
            }
            p{
                margin-top: 30px;
                font-size: 22px;
                text-indent: 2em;
                line-height: 26px;
            }
            table{
                margin-top: 30px;
                tbody{
                    tr{
                        th:nth-child(1){
                            background-color: #0067AC;
                            color: white;
                            font-size: 22px;
                            line-height: 57px;
                           width: 200px;
                           text-align: center;
                           border-bottom:1px solid white;
                        }
                        th:nth-child(2){
                            width: 645px;
                            border: 1px solid #0067AC;
                            font-size: 22px;
                            line-height: 57px;
                            text-align: center;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

.advantage{
     margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .advantage_content{
        .age{
            margin-top: 30px;
            ul{
                display: flex;
            justify-content: space-around;
            align-items: center;
            li{
                width: 249px;
                height: 229px;
                border: 2px solid #0067AC;
                padding: 0 10px;
                border-radius: 7px;
                h4{
                    margin-top: 30px;
                    font-size: 22px;
                    color: #0067AC;
                    font-weight: bold;
                }
                p{
                    margin-top: 50px;
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
                    }
        .vant{
            margin-top: 30px;
           
            display: flex;
            justify-content: space-around;
            align-items: center;
            .vant_left{
                ul{
                    li{
                        font-size: 22px;
                        margin-bottom: 10px;
                    }
                }
            }
            .vant_right{
                width: 380px;
                height: 340px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.case{
     margin-top: 77px;
    .title{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #0067AC;
        h3{
            position: absolute;
            line-height: 66px;
            padding: 0px 70px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: white;
            color: #0067AC;
            font-size: 28px;
            height: 66px;
            font-weight: bold;
        }
    }
    .case_content{
        padding: 30px;
        ul{
            li{
                margin-bottom: 30px;
                p{
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
}




.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #0067AC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #0067AC;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#0067AC;

	line-height: 24px;
	font-size:16px;
    padding:10px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        ul{
            li{
              
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







